import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
	background-color: var(--wbn-primary-color);
	color: #fff;
	border-radius: .5rem;
  border: none;
  cursor: pointer;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  min-height: 30px;
  outline: none;
  padding: .48571429em 1.5em;
  text-align: center;
	text-decoration: none;
	:disabled{
		opacity: 0.5;
		cursor: auto;
	}
`
const Button = ({children, ...props})=>{
	return <ButtonStyled {...props}>{children}</ButtonStyled>

}

export default Button;