import styled from 'styled-components';

const ResetPasswordWrapper = styled.div`
  min-height: calc(100vh - 280px);
	max-width: 600px;
	padding: 10px 30px;
	margin: 30px auto;
`;

export {
	ResetPasswordWrapper
}