import React, { useState } from "react";
import { RecoverWrapper } from "./style";
import { Button, InputText } from "components/atoms";
import axios from "axios";

import { useTranslation } from 'react-i18next';


const ForgotPassword = () => {
  const { t } = useTranslation();

  const [inputEmail, setInputEmail] = useState("");
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickRecover = async () => {
    // console.log("Recovering account");
    setIsLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_URL_AUTH}/recover-password-request`,
        { email: inputEmail.trim() }
      );
      if (response.data.success) {
        setShowMessageSent(true);
      } else {
        alert(t('recover.alert'));
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };
  return (
    <RecoverWrapper>
      <h4 style={{ color: "#3b3b3b" }}>{t('recover.title')}</h4>
      <p>{t('recover.form.email.label')}</p>
      <InputText
        name="recover-email"
        value={inputEmail}
        type="email"
        disabled={showMessageSent || isLoading}
        onChange={e => {
          setInputEmail(e.target.value);
        }}
        placeholder={t('recover.form.email.ph')}
      />
      <div style={{ margin: "20px 0px" }}>
        <Button onClick={handleClickRecover} disabled={showMessageSent || isLoading}>
          {t('recover.form.button')}
        </Button>
      </div>
      {showMessageSent ? <div>{t('recover.success.message') + ' '}{inputEmail}</div> : null}
    </RecoverWrapper>
  );
};

export default ForgotPassword;
