import React, { useState, useEffect } from "react";
import qs from "qs";
import axios from 'axios';
import { ResetPasswordWrapper } from "./style";
import { Button, InputText } from "components/atoms";

import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { t } = useTranslation();

  const [showResetForm, setShowResetForm] = useState(false);
  const [token, setToken] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordConfirm, setInputPasswordConfirm] = useState("");
  const [isResetting, setIsResetting] = useState(false);

  const handleClickRecover = async () => {
    setIsResetting(true);
    if (!inputPassword && !inputPasswordConfirm) {
      alert(t('reset.alert.p_required'));
    } else {
      if (inputPasswordConfirm !== inputPassword) {
        alert(t('reset.alert.p_confirm'));
      } else {
        try {
          let response = await axios.post(
            `${process.env.REACT_APP_URL_AUTH}/update-password`,
            { password: inputPassword, token: token }
          );
          if (response.data.success) {
            alert(t('reset.success.message'));
          } else if (response.data.error === 'SAME_LAST_PASSWORD') {
            alert(t('reset.error.same_last'));
          } else if (response.data.error === 'SAME_CURRENT_PASSWORD') {
            alert(t('reset.error.sane_current'));
            setInputPassword("")
            setInputPasswordConfirm("")
          } else {
            alert(t('reset.error.default'));
          }
        } catch (error) {
          console.error(error);
          alert(t('reset.error.500'));
        }
      }
    }
    setIsResetting(false);
  }

  useEffect(() => {
    let token = qs.parse(document.location.search, { ignoreQueryPrefix: true })
      .token;
    if (token) {
      setToken(token)
      setShowResetForm(true);
    }
  }, []);

  return (
    <ResetPasswordWrapper>
      <h4>{t('reset.form.title')}</h4>
      <p>{t('reset.form.subtitle')}</p>
      {showResetForm ? (
        <div>
          <div style={{ margin: "10px 0px" }}>
            <InputText
              name="new-pasword"
              value={inputPassword}
              type="password"
              onChange={e => {
                setInputPassword(e.target.value);
              }}
              placeholder={t('reset.form.pass1.ph')}
            />
          </div>
          <div>
            <InputText
              name="confirm-new-pasword"
              value={inputPasswordConfirm}
              type="password"
              onChange={e => {
                setInputPasswordConfirm(e.target.value);
              }}
              placeholder={t('reset.form.pass2.ph')}
            />
          </div>
          <div style={{ margin: "20px 0px" }}>
            <Button disabled={isResetting} onClick={handleClickRecover}>
              {t('reset.form.button')}
            </Button>
          </div>
        </div>
      ) : null}
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
