import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: require('./en.json')
      },
      es: {
        translations: require('./es.json')
      },
      it: {
        translations: require('./it.json')
      },
      "zh-Hans": {
        translations: require('./zh.json')
      }
    },
    fallbackLng: "en",
    debug: process.env.REACT_APP_DEBUG_TRANSLATE === 'true',
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      wait: true
    }
  });

export default i18n;