
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ContainerFooter } from './style';

const Footer = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (e) => {
    e.preventDefault();
    i18n.changeLanguage(e.target.name);
  }

  return (
    <ContainerFooter>
      <div>
        <Link onClick={changeLanguage} to="/" name="en">English</Link>
        <Link onClick={changeLanguage} to="/" name="es">Español</Link>
        <Link onClick={changeLanguage} to="/" name="it">Italiano</Link>
        <Link onClick={changeLanguage} to="/" name="zh-Hans">Chinese</Link>
      </div>
      <div>
        <p>{t('footer.year')}</p>
        <p>{t('footer.wbn')}</p>
      </div>
    </ContainerFooter>
  )
};

export default Footer;