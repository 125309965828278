import styled from 'styled-components';

const InputText = styled.input`
	border-radius: 20px;
	height: 32px;
	width: 275px;
	padding: 5px 15px;
	border: 1px solid var(--wbn-sidebar-color-line);
`

export default InputText;