import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({is_student: false, accept_terms: false});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
		// console.log("[useForm.js] useEffect")
    if (Object.keys(errors).length === 0 && isSubmitting) {
			if(!isSubmitting)setIsSubmitting(true);
      callback(values);
		}else{
			if(isSubmitting)setIsSubmitting(false);
		}
  }, [errors, callback, isSubmitting, values]);

  const handleSubmit = (event) => {
		// console.log("[useForm.js] Handling submit")
    if (event) event.preventDefault();
    setErrors(validate(values));
    if(!isSubmitting)setIsSubmitting(true);
  };

  const handleChange = (event, t) => {
		if(event.persist)event.persist();
		// console.log("T", t);
		setValues(values => ({ ...values, [t.name]: t.value }));
		setErrors({...errors, [t.name]:false });
  };

  return {
    handleChange,
    handleSubmit,
		values,
		setValues,
		errors,
		isSubmitting,
		setIsSubmitting
  }
};

export default useForm;