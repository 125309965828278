import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Message, Icon } from 'semantic-ui-react';
import axios from 'axios';
import './styles.css';

import { useTranslation } from 'react-i18next';

const authInstance = axios.create({
	baseURL: process.env.REACT_APP_URL_AUTH
});

const Confirm = ({ match, location }) => {
  const { t } = useTranslation();

	const [validationState, setValidationState] = useState({
		iconName: 'circle notched',
		loading: true,
		color: 'purple',
		messageHeader: 'confirm.wait.header',
		message: 'confirm.wait.message',
  });

	useEffect(() => {
		let token = qs.parse(window.location.search.slice(1)).token;
		const validateToken = async () => {
			try {
				let response = await authInstance.post('/email-confirmation', {
					token: token || "",
				});
				// console.log("Response", response.data.data);
				if (response.data.data.reconfirmation) {
					setValidationState({
						iconName: 'info circle',
						color: 'orange',
						loading: false,
						messageHeader: 'confirm.reconfirm.header',
						message: 'confirm.reconfirm.message',
					});
				} else {
					setValidationState({
						iconName: 'checkmark',
						color: 'green',
						loading: false,
						messageHeader: 'confirm.success.header',
						message: 'confirm.success.message',
					});
				}
			} catch (error) {
				setValidationState({
					iconName: 'remove circle',
					color: 'red',
					loading: false,
					messageHeader: 'confirm.error.header',
					message: 'confirm.error.message',
				});
			}
		}
		validateToken();
	}, [location.search]);
	return (
		<div className="message-container">
			<Message icon>
				<Icon
					name={validationState.iconName}
					color={validationState.color}
					loading={validationState.loading} />
				<Message.Content>
					<Message.Header>{t(validationState.messageHeader)}</Message.Header>
					{t(validationState.message)}
				</Message.Content>
			</Message>
		</div>
	)
}

export default React.memo(Confirm);