import React from "react";
import WBN_Map from "images/worldwide_manufacturing_suppliers_vendors_business_network_map.png";

import "./styles.css";
import RegisterForm from "./RegisterForm";
import Helmet from "react-helmet";

import { useTranslation } from 'react-i18next';

const Register = props => {
  const { t } = useTranslation();

  return (
    <div className="container-register">
      <Helmet>
        <title>
          {process.env.REACT_APP_SEO_FIRST} WBN Industrial B2B Social Network registration process, {process.env.REACT_APP_SEO_FIRST}
          industrial manufacturing inscription to export worldwide, made in
          {process.env.REACT_APP_SEO_SECOND} B2B industrial export platform registration for {process.env.REACT_APP_SEO_FIRST}
          distributors manufacturers vendors, wholesale suppliers
        </title>
        <meta
          name="description"
          content={`WBN registration process, register your ${process.env.REACT_APP_SEO_FIRST} manufacturer company into our industrial B2B Social Network to the best B2B export experience, registration into the ${process.env.REACT_APP_SEO_FIRST} B2B industrial export manufacturing supplies, made in ${process.env.REACT_APP_SEO_SECOND} manufacturing vendors for B2B global distributors, WBN your best source of manufacturer suppliers and vendors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale business distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}
        />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_FIRST} manufacturer registration, ${process.env.REACT_APP_SEO_FIRST} business registration, ${process.env.REACT_APP_SEO_FIRST} distributors industry, ${process.env.REACT_APP_SEO_FIRST} buyers industry, b2b social network, industrial social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_FIRST} manufacturer suppliers`} />
      </Helmet>
      <div className="container-register__body">
        <div className="container-register-main">
          <div className="container-register-main__left">
            <div className="container-register-main__left--text">
              <p>
                {t('register.left.top')}{" "}
              </p>
            </div>
            <div className="container-register-main__left--image">
              <img src={WBN_Map} alt="Mapa" />
            </div>
            <div className="container-register-main__left--text">
              <p>
                {t('register.left.bottom')}
              </p>
            </div>
          </div>
          <div className="container-register-main__right">
            <RegisterForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
