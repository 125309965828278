
import styled from 'styled-components';

export const ContainerFooter = styled.div`
  bottom: 0px;
  left: 0px;
  right: 0px;

  > div:first-child {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    font-weight: 600;
    margin: 8px 0;
  }

  > div:first-child a {
    color: #3260A9;
    font-size: 12px;
    text-decoration: none;
  }

  > div:first-child a:hover {
    color: #000000;
    text-decoration: underline;
  }

  > div:nth-child(2) {
    padding: 16px;
    text-align: center;
    background-color: #A3AAA3;
    color: #ffffff;
  }

  > div:nth-child(2) > p {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    > div:first-child {
      justify-content: center;
    }

    > div:first-child a {
      margin: 0 32px;
    }

    > div:nth-child(3) {
      padding: 16px;
    }
  }
`;
