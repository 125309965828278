import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Header,
  Checkbox,
  Message
} from "semantic-ui-react";
import axios from "axios";
import qs from "qs";
import useForm from "./useForm";
import validate from "./ValidationRules";
import logoWbn from "domain_brand";
import IconPerson from "../../images/ICONS_WBN_PROFILE_PICTURE.svg";
import { useTranslation } from 'react-i18next';

import "./styles.css";

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}`
});

const RegisterForm = props => {
  const { t } = useTranslation();

  const {
    values,
    setValues,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting
  } = useForm(postSignup, values => validate(values, t));


  async function postSignup() {
    let formValues = { ...values };
    delete formValues["confirm_password"];
    delete formValues["is_student"];
    delete formValues["company_name"];
    delete formValues["job_position"];
    delete formValues["accept_terms"];
    try {
      let continueLink =
        qs.parse(window.location.search.slice(1)).continueLink ||
        process.env.REACT_APP_URL_FRONT;
      let response = await axios.post(
        `${process.env.REACT_APP_URL_AUTH}/signup?continue=${encodeURIComponent(continueLink)}`,
        {
          ...formValues,
          isStudent: values.is_student,
          businessType: values.business_type,
          companyName: values.company_name,
          jobPositionID: values.job_position
        }
      );
      if (isSubmitting) setIsSubmitting(false);
      if (response.data.success) {
        setSignupState({
          done: true,
          error: false,
          message: (
            <p>
              {t('register.success.part1')}{" "}
              <strong>{values.email}</strong> {t('register.success.part2')}
            </p>
          )
        });
      }
    } catch (error) {
      let message = error.response ? error.response.data.message : "Error";
      if (isSubmitting) setIsSubmitting(false);
      setSignupState({ done: true, error: true, message: message });
    }
  }
  const [signupState, setSignupState] = useState({ done: false, error: false });
  const [countriesState, setCountries] = useState({ countries: [] });
  const [countryStateState, setCountryState] = useState({ countryStates: [] });

  const fetchCountries = async () => {
    const response = await apiInstance.get(`/ref-data/country`);
    let countries = response.data.data.map(e => ({
      text: e.emoji + " " + e.countryName,
      value: e.code,
      phonecode: e.phoneCode
    }));
    setCountries({ countries: countries });
  };

  useEffect(() => {
    const fetchAll = () => {
      fetchCountries();
    };
    fetchAll();
  }, []);

  const fetchCountryStates = async countryCode => {
    if (!countryCode) return;

    const response = await apiInstance.get(
      `/ref-data/country/${countryCode}/state		`
    );
    let countryStates = response.data.data.map(e => ({
      text: e.stateName,
      value: e.stateID
    }));
    setCountryState({ countryStates: countryStates });
    setValues(values => ({ ...values, state: null }));
  };

  const handleFocus = e => {
    e.target.setAttribute("autocomplete", "nope");
  };
  const handleCountrySelect = (e, t) => {
    fetchCountryStates(t.value);
  };

  const handleAcceptTerms = (e, t) => {
    setValues(values => ({ ...values, accept_terms: t.checked }));
    handleChange(e, { ...t, value: t.checked });
  };

  return (
    <div className="register-form">
      <p>{t('register.form.title')}</p>
      {signupState.done ? (
        !signupState.error ? (
          <>
            <div className="register-form__success-confirm">
              <Message
                success
                header={t('register.complete.message')}
                content={signupState.message}
              />
            </div>
            <div className="register-form__success-confirm-wrapper">
              <img
                src={`/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/dabie_wbn_b2b_registration_completed.svg`}
                alt="DABIE WBNB2B WBN PET SYMBOL REGISTER WITH WBN B2B THE INDUSTRIAL B2B SOCIAL NETWORK"
                className="register-form__success-confirm-image"
              />
            </div>
          </>
        ) : (
            <Message
              warning
              header={t('register.failed.message')}
              content={signupState.message}
            />
          )
      ) : null}
      {(signupState.done && !signupState.error) || (
        <Form onSubmit={handleSubmit} noValidate>
          <div className="flex-row">
            <div className="col-eq form-field__parent">
              <Form.Field
                id="first-name"
                fluid
                name="firstName"
                onChange={handleChange}
                value={values.firstName || ""}
                control={Input}
                label={t('register.form.first_name.label')}
                placeholder={t('register.form.first_name.ph')}
                error={errors.firstName && t(errors.firstName)}
              />
            </div>
            <div className="col-eq form-field__parent">
              <Form.Field
                id="last-name"
                fluid
                name="lastName"
                onChange={handleChange}
                control={Input}
                value={values.lastName || ""}
                label={t('register.form.last_name.label')}
                placeholder={t('register.form.last_name.ph')}
                error={errors.lastName && t(errors.lastName)}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="col-eq form-field__parent">
              <Form.Field
                fluid
                control={Select}
                name="country"
                options={countriesState.countries}
                label={{ children: t('register.form.country.label'), htmlFor: "country" }}
                value={values.country || ""}
                onChange={(e, t) => {
                  handleChange(e, t);
                  handleCountrySelect(e, t);
                }}
                placeholder={t('register.form.country.ph')}
                error={errors.country && t(errors.country)}
                searchInput={{ id: "country" }}
                onFocus={handleFocus}
                search
              />
            </div>

            <div className="col-eq form-field__parent">
              <Form.Field
                fluid
                name="state"
                control={Select}
                options={countryStateState.countryStates}
                onChange={(e, t) => {
                  handleChange(e, t);
                }}
                label={{ children: t('register.form.state.label'), htmlFor: "state" }}
                placeholder={t('register.form.state.ph')}
                error={errors.state && t(errors.state)}
                searchInput={{ id: "state" }}
                onFocus={handleFocus}
                search
              />
            </div>
          </div>
          <div className="register-form__middle">
            <div className="register-form__middle--left form-field__parent">
              <Form.Field
                id="city"
                name="city"
                onChange={handleChange}
                control={Input}
                label={t('register.form.city.label')}
                fluid
                placeholder={t('register.form.city.ph')}
              />
              <Header as="h5" className="register-form__account-info">
                <img src={IconPerson} alt="REGISTER ICON PERSON WBNB2B WBN" />
                {t('register.form.account_information')}
              </Header>
              <Form.Field
                id="email"
                name="email"
                onChange={handleChange}
                control={Input}
                label={t('register.form.email.label')}
                error={errors.email && t(errors.email)}
                type="email"
                fluid
                placeholder={t('register.form.email.ph')}
              />
            </div>

            <div className="register-form__middle--right">
              <img
                src={logoWbn}
                alt="logo_wbn"
                className="container-sidebar-left__logo"
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="col-eq form-field__parent">
              <Form.Field
                id="password"
                control={Input}
                name="password"
                onChange={handleChange}
                type="password"
                label={t('register.form.password.label')}
                fluid
                error={errors.password && t(errors.password)}
                placeholder={t('register.form.password.ph')}
              />
            </div>

            <div className="col-eq form-field__parent">
              <Form.Field
                id="confirm_password"
                name="confirm_password"
                control={Input}
                onChange={handleChange}
                type="password"
                label={t('register.form.password2.label')}
                error={errors.confirm_password && t(errors.confirm_password)}
                fluid
                placeholder={t('register.form.password2.ph')}
              />
            </div>
          </div>
          <div className="flex-row register-form__checkbox">
            <Form.Field
              control={Checkbox}
              label={{
                children: (
                  <p style={{ fontSize: "12px" }}>
                    {t('register.form.tc.p1')}{" "}
                    <a
                      href={`${process.env.REACT_APP_URL_FRONT}terms-and-conditions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('register.form.tc.p2')}
                    </a>
                  </p>
                )
              }}
              name="accept_terms"
              error={errors.accept_terms}
              onChange={handleAcceptTerms}
            ></Form.Field>
          </div>
          <Form.Group className="register-form__confirm">
            <Form.Field
              id="submit"
              control={Button}
              loading={isSubmitting}
              disabled={isSubmitting}
              content={t('register.form.button')}
              style={{
                backgroundColor: "var(--wbn-sidebar-color-line)",
                color: "white",
                fontWeight: 500,
                borderRadius: "8px"
              }}
            />
          </Form.Group>
        </Form>
      )}
    </div>
  );
};
export default RegisterForm;
