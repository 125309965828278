import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import qs from "qs";
import { Form, Message, Icon } from "semantic-ui-react";
import logoMessage from "images/WBN_B2B_MANUFACTURERS_DISTRIBUTORS_BACKGROUND.svg";
import axios from "axios";
import Helmet from "react-helmet";
import "./styles.css";

import { useTranslation, Translation } from 'react-i18next'

const LoginForm = () => {
  const { t } = useTranslation();

  const [continueLink, setContinueLink] = useState("");
  const [emailState, setEmail] = useState("");
  const [passwordState, setPassword] = useState("");
  const [messageResponse, setMessageResponse] = useState({
    show: false,
    header: "",
    content: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    let continueLink =
      qs.parse(window.location.search.slice(1)).continue ||
      process.env.REACT_APP_URL_FRONT;
    setContinueLink(continueLink);
  }, [continueLink]);

  const postLogin = async e => {
    setIsSubmitting(true);
    setMessageResponse({
      show: false,
    });
    e.persist();
    e.preventDefault();
    let data = {
      email: emailState.trim().toLowerCase(),
      password: passwordState
    };
    try {
      let resp = await axios.post(`${process.env.REACT_APP_URL_AUTH}/login`, data, {
        withCredentials: true
      });
      let sidToken = resp.data.wbnSid;
      window.location.href = `${process.env.REACT_APP_URL_AUTH}/serviceLogin?continue=${encodeURIComponent(continueLink)}&sid-token=${sidToken}`;
    } catch (error) {
      if (error.response) {
        if (error.response.data.name === "AccountVerificationPending") {
          setMessageResponse({
            show: true,
            header: 'login.confirm_email.header',
            content: 'login.confirm_email.content',
            url: 'login.confirm_email.button',
          });
        } else {
          setMessageResponse({
            show: true,
            header: "login.wrong_credentails.header",
            content: "login.wrong_credentails.content"
          });
        }

        // console.log("Error en login");
      } else {
        // console.log("Error en red");
        setMessageResponse({
          show: true,
          header: "general.network-error.header",
          content: "general.network-error.content"
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendEmail = async email => {
    try {
      await axios.post(
        `${process.env.REACT_APP_URL_AUTH}/resend-email-confirmation`,
        { email: email }
      );
      alert(t('login.resend_email.sent'));
    } catch (error) {
      alert(t('login.resend_email.error'));
    }
  };

  return (
    <div className="container-login">
      <Helmet>
        <title>{`${process.env.REACT_APP_SEO_FIRST} WBN login into the industrial B2B social network, ${process.env.REACT_APP_SEO_FIRST} gateway to the industrial manufacturing business suppliers, login into WBN the best export platform for manufacturers, distributors, wholesale suppliers and retail vendors, ${process.env.REACT_APP_SEO_FIRST}`}</title>
        <meta
          name="description"
          content={`${process.env.REACT_APP_SEO_FIRST} WBN login to the best industrial B2B social network, a great gateway to the ${process.env.REACT_APP_SEO_FIRST} manufacturers companies in an industrial B2B Social Network to export global experience, made in ${process.env.REACT_APP_SEO_SECOND} products in the ${process.env.REACT_APP_SEO_FIRST} B2B industrial export manufacturing supplies, made in ${process.env.REACT_APP_SEO_SECOND} manufacturing vendors for B2B global distributors, WBN your best source of manufacturer suppliers and vendors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale business distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, Italian product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}
        />
        <meta
          name="keywords"
          content={`${process.env.REACT_APP_SEO_FIRST} manufacturing login, ${process.env.REACT_APP_SEO_FIRST} business login, ${process.env.REACT_APP_SEO_FIRST} distributors industry, ${process.env.REACT_APP_SEO_FIRST} buyers industry, ${process.env.REACT_APP_SEO_SECOND} social network, industries social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing suppliers`}
        />
      </Helmet>
      <div className="container-login__content--left">
        <div
          className="container-login__content--left-parent"
          style={{
            backgroundImage: `url(${logoMessage})`
          }}
        >
          <div className="container-login__content--left-message">
            <p>{t('login.wbn.p1')}</p>
            <p>{t('login.wbn.p2')}</p>
            <p>{t('login.wbn.p3')}</p>
            <p>{t('login.wbn.p4')}</p>
          </div>
        </div>
      </div>
      <div className="container-login__content--right">
        <div className="container-login__content--right-parent">
          <div className="container-login__content--right-title">
            {/*<img src={logoImage} className="container-login__content-img" />*/}
          </div>
          <div className="container-login__content--right-form">
            {messageResponse.show && (
              <Message
                header={t(messageResponse.header)}
                content={() =>
                  <Translation>
                    {t => <div>
                      {t(messageResponse.content)}
                      {messageResponse.url && (
                        <Form.Button onClick={() => handleResendEmail(emailState)}>
                          {t(messageResponse.url)}
                        </Form.Button>
                      )}
                    </div>
                    }
                  </Translation>
                }
                color="orange"
              />
            )}
            <Form size="large" onSubmit={postLogin} method="POST">
              <Form.Input
                fluid
                name="email"
                value={emailState}
                onChange={e => setEmail(e.target.value)}
                iconPosition="left"
                required
                placeholder={t('login.form.email_ph')}
                type="email"
              />
              <Form.Input
                fluid
                name="password"
                value={passwordState}
                onChange={e => setPassword(e.target.value)}
                iconPosition="left"
                placeholder={t('login.form.password_ph')}
                required
                type="password"
              />
              <Form.Button
                style={{
                  backgroundColor: "var(--wbn-sidebar-color-line)",
                  color: "white"
                }}
                size="large"
                disabled={isSubmitting}
                content={t('login.form.button_text')}
              ></Form.Button>

              <div
                style={{
                  textAlign: "center",
                  fontSize: "12px"
                }}>
                <p>{t('login.form.no_account')}</p>
                <Link to="/register">
                  <button className="button-register">
                    <div style={{ fontSize: "12px" }}>
                      <Icon className="wbn-member" />
                      {t('login.form.become_member')}
                    </div>
                  </button>
                </Link>
                <Link to="/recover-password">
                  <div style={{ margin: "5px" }}>{t('login.form.forgot_password')}</div>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
