import React, {  } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import 'wbn_domain_styles';

import { Layout } from './components';
import { Login, Signup, Confirm, RecoverPassword, ResetPassword } from './pages';

function App() {
	return (
		<Router>
			<Layout>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/register" component={Signup} />
					<Route exact path="/email-confirmation" component={Confirm} />
					<Route exact path="/recover-password" component={RecoverPassword} />
					<Route exact path="/reset-password" component={ResetPassword} />
					<Redirect to='/login' />
				</Switch>
			</Layout>
		</Router>
	);
}

export default App;

