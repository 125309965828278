import React from 'react';
import Header from '../header';
import Footer from '../footer';

const Layout = ({ children }) => {
  return (
    <div style={{maxWidth: "1200px", margin: "0 auto"}}>
      <Header />
      { children }
      <Footer />
    </div>
  )
};

export default Layout;