const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function validate(values, t) {
	// console.log("Validating")
	let errors = {};
	if (!values.firstName) {
		errors.firstName = 'register.validate.firstname';
	}
	if (!values.lastName) {
		errors.lastName = 'register.validate.lastname';
	}
	/*if (!values.gender) {
		errors.gender = 'You must select a gender';
	}*/
	if (!values.country) {
		errors.country = 'register.validate.country';
	}
	if (!values.state) {
		errors.state = 'register.validate.state';
	}
  
	if (!values.email) {
		errors.email = 'register.validate.email1';
	} else if (!re.test(String(values.email).toLowerCase())) {
		errors.email = 'register.validate.email2'
	}
	if (!values.password) {
		errors.password = 'register.validate.password1';
	} else if (values.password !== values.confirm_password) {
		errors.confirm_password = `Password doesn't match.`;
	}else if (values.password.length < 6){
		errors.password = 'register.validate.password2';
	}

	if (!values.accept_terms) {
		errors.accept_terms = {
			content: t('register.validate.terms'),
			pointing: 'left',
		};
	}
	// console.log("Errors", errors);
	return errors;
}
